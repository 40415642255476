<template>
  <v-form ref="additionalWebinarWorksheet">
    <v-card
        max-width="640"
        class="mx-auto"
        flat
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-app-bar
                  dark
                  color="#4285F4"
                  height="10px"
              >
              </v-app-bar>
              <v-card-title class="text-h5">
                Анкета для участников вебинара 28.09.2023
              </v-card-title>
              <v-card-text>
                <v-card-subtitle class="errorColor">* - Обязательный вопрос</v-card-subtitle>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Нуждаетесь ли в дополнительных совещаниях по организационным вопросам работы с банками оценочных
                  средств (для организаций-соразработчиков)
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group v-model="info.needOrgQuestionMeeting" :rules="[v => v !== '']">
                  <v-radio
                      label="Да"
                      :value="true"
                  />
                  <v-radio
                      label="Нет"
                      :value="false"
                  />
                </v-radio-group>
                <v-autocomplete
                    v-if="info.needOrgQuestionMeeting"
                    v-model="info.orgMeetingSpecialityId"
                    :items="specialities"
                    item-value="code"
                    item-text="name"
                    label="Укажите специальность"
                    :rules="[v => v.length > 0]"
                    multiple
                    chips
                    clearable
                    no-data-text="Специальностей не найдено"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Необходимы ли образовательные мероприятия <b>для авторов тестовых заданий</b> (далее - ТЗ)
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-row class="centerAligment">
                  <v-col cols="6">
                    - по вопросу разработки ТЗ
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info.meetingForTestDevelopment"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly"
                    >
                      <v-radio
                          label="Да"
                          :value="true"
                      />
                      <v-radio
                          label="Нет"
                          :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row class="centerAligment">
                  <v-col cols="6">
                    - по вопросу представления ТЗ в &nbsp;&nbsp;Методический центр
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info.meetingForTestProvision"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly"
                    >
                      <v-radio
                          label="Да"
                          :value="true"
                      />
                      <v-radio
                          label="Нет"
                          :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Необходимы ли образовательные мероприятия <b>для авторов ситуационных задач</b> (далее - кейсов)
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-row class="centerAligment">
                  <v-col cols="6">
                    - по вопросу разработки кейсов
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info.meetingForCaseDevelopment"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly"
                    >
                      <v-radio
                          label="Да"
                          :value="true"
                      />
                      <v-radio
                          label="Нет"
                          :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row class="centerAligment">
                  <v-col cols="6">
                    - по вопросу работы в ИС "Авторская"
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info.meetingForAuthorSystem"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly"
                    >
                      <v-radio
                          label="Да"
                          :value="true"
                      />
                      <v-radio
                          label="Нет"
                          :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Необходимы ли образовательные мероприятия <b>для экспертов тестовых заданий</b> (далее - ТЗ)
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-row class="centerAligment">
                  <v-col cols="6">
                    - по вопросу работы в ИС "Экспертиза"
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info.meetingForExpertiseSystem"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly"
                    >
                      <v-radio
                          label="Да"
                          :value="true"
                      />
                      <v-radio
                          label="Нет"
                          :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-subtitle>
                <span style="color: black !important;">
                  Остались ли у Вас вопросы по письму Минздрава России от 31.08.2023 об актуализации банков оценочных
                  средств для аккредитации 2024 года.
                </span>
              </v-card-subtitle>
              <v-card-text>
                <v-radio-group v-model="info.minzdravLetterQuestions" :rules="[v => v !== '']">
                  <v-radio
                      label="Да"
                      :value="true"
                  />
                  <v-radio
                      label="Нет"
                      :value="false"
                  />
                </v-radio-group>
                <v-text-field
                    v-if="info.minzdravLetterQuestions"
                    v-model="info.minzdravLetterQuestionsText"
                    label="Напишите свой вопрос"
                    :rules="[v => v !== '']">
                </v-text-field>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Оцените по пятибалльной шкале регистрацию в ИС "Мероприятия Методического центра"
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-row class="centerAligment">
                  <v-col cols="6">
                    Удобство
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info.registrationComfort"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly"
                    >
                      <v-radio
                          v-for="n in [5, 4, 3, 2]"
                          :key="`registrationComfort-${n}`"
                          :label="n"
                          :value="n"
                      >
                        <template #label>
                          <div style="position:absolute; bottom: 20px; right:17px">{{ n }}</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="centerAligment">
                  <v-col cols="6">
                    Простота
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info.registrationSimplicity"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly"
                    >
                      <v-radio
                          v-for="n in [5, 4, 3, 2]"
                          :key="`registrationSimplicity-${n}`"
                          :label="n"
                          :value="n"
                      >
                        <template #label>
                          <div style="position:absolute; bottom: 20px; right:17px">{{ n }}</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="centerAligment">
                  <v-col cols="6">
                    Доступность
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info.registrationAccessibility"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly"
                    >
                      <v-radio
                          v-for="n in [5, 4, 3, 2]"
                          :key="`registrationAccessibility-${n}`"
                          :label="n"
                          :value="n"
                      >
                        <template #label>
                          <div style="position:absolute; bottom: 20px; right:17px">{{ n }}</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-btn color="primary" class="worksheetSubmitBtn" @click="submitForm">Отправить</v-btn>
    </v-card>
  </v-form>
</template>

<script>
import { dbMixin, formsMixin } from "@/mixins"
import { mapGetters } from "vuex"

export default {
  name: 'AdditionalWebinarWorksheet',
  mixins: [dbMixin, formsMixin],
  data () {
    return {
      specialities: [],
      yesNoList: [{value: true, text: 'Да'}, {value: false, text: 'Нет'}],
      info: {
        type: 'additionalWebinar',
        needOrgQuestionMeeting: '',
        orgMeetingSpecialityId: '',
        meetingForTestProvision: '',
        meetingForTestDevelopment: '',
        meetingForCaseDevelopment: '',
        meetingForAuthorSystem: '',
        meetingForExpertiseSystem: '',
        minzdravLetterQuestions: '',
        minzdravLetterQuestionsText: '',
        registrationComfort: '',
        registrationSimplicity: '',
        registrationAccessibility: ''
      }
    }
  },
  beforeMount () {
    this.getCatalogData('specialities')
  },
  computed: {
    ...mapGetters(['USER']),
  },
  methods: {
    async submitForm () {
      if (!this.$refs.additionalWebinarWorksheet.validate()) {
        return
      }
      const url = `${process.env.VUE_APP_SERVER_URL}/worksheet/register`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...this.info, personId: this.USER.userId, worksheetId: this.$route.query.id }),
      });
      if (response.status === 200) {
        await this.$dialog.notify.success('Анкета сохранена', { timeout: 1500 })
      } else {
        await this.errorHandler(response, 'Ошибка при сохранении анкеты')
      }
    }
  }
}


</script>

<style scoped>
.centerAligment {
  align-items: center
}
</style>

